const prices = [
  {
    id: 1,
    title: 'Domestic',
    price: 'From £15/hr',
    list: [
      'Thorough cleaning of bathrooms and kitchens.',
      'Vacuuming entire house',
      'Wipe & dust all reachable areas',
      'Thorough cleaning of fridge and oven',
      'Dusting of skirting boards, pictures, lamp shades, knick-knacks and ornaments, window sills, furniture and light fittings.',
      'Spot cleaning of paint work and more – all you have to do is ask!',
    ],
  },
  {
    id: 2,
    title: 'Commercial',
    price: 'Reliant on quote',
    list: [
      'Office cleaning',
      'Event cleaning',
      'Pub & restaurant cleaning',
      'Sports & leisure cleaning',
      'Plus much more!',
    ],
  },
  {
    id: 3,
    title: 'Other',
    price: 'Varies',
    list: [
      'End of tenancy cleaning',
      'Renovation cleaning',
      'Home Events cleaning',
      'Holiday let cleaning',
      'Deep clean - one off',
      'Oven cleaning',
      'Patio power washing',
      'Gardening/clearance',
      'Plus much more!',
    ],
  },
];

export default prices;
