const Quotes = [
  {
    id: 1,
    name: 'Kathy B',
    quote:
      'Just letting you know that my cats and I are very pleased with our house after you came. Thanks!',
    date: 'August 2017',
  },
  {
    id: 2,
    name: 'Julie M',
    quote: `Hi Mira, Just letting you know that Helena did a great job. The house looks and smells absolutely fabulous! Thank you`,
    date: 'December 2017',
  },
  {
    id: 3,
    name: 'Liz S',
    quote: `Just wanted to say thank you to Earl and Anna - they’ve done a brilliant job! Thank you for everything Mira!`,
    date: 'May 2018',
  },
  {
    id: 4,
    name: 'David H',
    quote: `Hello Mira, I just want to say how pleased I am with Sara's cleaning. Today was the first day on her own and she did an excellent job. Many thanks`,
    date: 'October 2018',
  },
  {
    id: 5,
    name: 'Sam',
    quote: `Hi Mira, the girls did an excellent job. Very happy with your service.`,
    date: 'August 2019',
  },
  {
    id: 6,
    name: 'Viv & Paul',
    quote: `We contacted Mira's Maids for a "One off Clean". There were dirty cupboards, paintwork, banisters, bookcases etc. to bring back to life. Mira checked the house herself prior to the clean, and ladies Dasa and Sarah did a totally brilliant job for us. We are so pleased, what a load off our minds!!`,
    date: 'October 2019',
  },
  {
    id: 7,
    name: 'Geoffrey Darington',
    quote: `Mira is very conscientious and hard working. I have known Mira for a long time, and she has proved to be reliable and efficient. She has recently taken on the the job of cleaning the communal areas of the apartment block in which I live, The Grand Folkestone.`,
    date: 'November 2022',
  },
  {
    id: 8,
    name: 'Tina Thomson',
    quote: `Mira's cleaning services are fantastic; they are professional and passionate about their work and ensure that you are satisfied as their client. I highly recommend their services.`,
    date: 'January 2023',
  },
  {
    id: 9,
    name: 'Paul',
    quote:
      'Mira and her team are such a terrific asset to me. I have 2 flats that I rent out and I have complete confidence in her to deep clean every time there is a change in tenant and I know that the flats are spotless and ready to welcome new occupants. Thank you Mira for everything!',
    date: 'January 2025',
  },
];

export default Quotes;
